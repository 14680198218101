/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'

import Header from './Header.react';
import Single from './Single.react';
import singles from './singles';
import CookieConsentFooter from './CookieConsentFooter.react';
import {useSlim, useLinks} from './urlHelpers';

const MainPage = () => {
    const slim = useSlim();
    const links = useLinks();

    return (
        <>
            <div css={styles.main}>
                <Header />
                <div css={styles.singles}>
                    {singles.map(s => (<Single key={s.title} single={s} showDesc={!slim} links={links} />))}
                </div>
            </div>

            <CookieConsentFooter />
        </>
    )
}

const styles = {
    main: css`
        font-size: 16px;
        color: #eee;
        text-size-adjust: 100%; 
        -ms-text-size-adjust: 100%; 
        -moz-text-size-adjust: 100%; 
        -webkit-text-size-adjust: 100%;	
    `,
    singles: css`
        // margin-top: 40px;
        overflow: hidden;

        // @media only screen and (max-width: 1000px) {
        //     width: initial;
        //     margin-top: 30px;
        // }
        margin-top: 30px;
    `,
}

export default MainPage;
