/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'
import { useParams } from "react-router-dom";
import singles from './singles';
import Single from './Single.react';
import CookieConsentFooter from "./CookieConsentFooter.react";
import {useSlim, useLinks, useAdGreeting} from './urlHelpers';


const SinglePage = () => {
    const params = useParams();
    const slim = true;
    // const slim = useSlim();
    const links = useLinks();
    const adGreeting = useAdGreeting();

    const single = singles.find(s => s.url === params.singleId);
    if (single == null) {
        return <div css={styles.pfff}>Pfff I haven't written that song yet</div>
    }

    return (
        <>
            <Single single={single} showDesc={!slim} links={links} adGreeting={adGreeting} />
            {!slim && (
                <a css={styles.more} href="/">more from johnny_kilobyte &gt;</a>
            )}
            <CookieConsentFooter />
        </>
    )
}

const styles = {
    pfff: css`
        margin: 100px auto;
        width: 250px;
    `,
    more: css`
        display: block;
        text-align: center;
        margin: 1em;
        font-size: 1rem;
    `,
}

export default SinglePage;
