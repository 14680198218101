/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'


const SingleActionButton = ({ serviceName, singleTitle, href, title, logo, releaseDate }) => {
    return (
        <a css={styles.serviceline} href={href} onClick={() => {
            if (typeof window !== "undefined") {
                if (window.fbq != null) {
                    window.fbq('trackCustom', `NavigateTo`, { title: singleTitle, service: serviceName });
                }
            }
        }}>
            <img css={styles.servicelogo} src={logo} alt={serviceName} />
            <div css={styles.buttonInfo}>
                <button css={styles.serviceplay} title={title}>
                    <span css={styles.serviceplaytext} className="buttonText">{title}</span>
                </button>
                {releaseDate != null && (
                    <span css={styles.releaseDate}>Coming on {releaseDate}!</span>
                )}
            </div>
        </a>
    )
}

const styles = {
    releaseDate: css`
        display: block;
        text-align: center;
        font-size: 0.5em;
        line-height: 0.8em;
        color: red;
        margin-top: 4px;
    `,
    buttonInfo: css`
        display: flex;
        flex-direction: column;
        align-items: center;
    `,
    serviceline: css`
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        :hover {
            background-color: #eee;
        }
        :hover span.buttonText {
            background-color: #000;
            border-color: #000;
            color: #fff;
        }
    `,
    servicelogo: css`
    	width: 120px;
    `,
    serviceplay: css`
        border: 0;
        background-color: transparent;
        color: #777;
        background: linear-gradient(180deg,#fff,#f5f5f5);
        border-radius: 0.3rem;
        cursor: pointer;
        padding: 0;
        width: fit-content;
    `,
    serviceplaytext: css`
        background-color: transparent;
        display: inline-block;
        border: 1px solid transparent;
        border-radius: 0.3rem;
        border-color: #e1e1e1;
        padding: 0 1rem;
        box-sizing: border-box;
        line-height: 2rem;
        font-size: 1rem;
    `,
}

export default SingleActionButton;
