import MainPage from './MainPage.react';
import SinglePage from './SinglePage.react';
import Page404 from './Page404.react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import CookiePolicyPage from './CookiePolicyPage.react';


const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="single" element={<SinglePage />}>
                    <Route path=":singleId" element={<SinglePage />} />
                </Route>
                <Route path="cookiepolicy" element={<CookiePolicyPage />} />
                <Route path="*" element={<Page404 />} />
            </Routes>
        </BrowserRouter>
    )
}
export default App;
