import React from 'react';
import { useLocation } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const useSlim = () => {
    const query = useQuery();
    return query.get("slim") != null;
    // return true;
}

export const useLinks = () => {
    const query = useQuery();
    const linksStr = query.get("links");
    if (linksStr == null) {
        return null;
    }
    return linksStr.split(',');
}

export const useAdGreeting = () => {
    const query = useQuery();
    return query.get("adgreeting") != null;
}