/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import SingleActionButton from './SingleActionButton.react';


const Single = ({ single, showDesc = false, adGreeting = false, links = null }) => {

    return (
        <div css={styles.main}>
            <img css={styles.cover} src={`/images/singles/${single.url}.png`} alt="album cover" />
            <div css={styles.header}>
                <h2>johnny_kilobyte</h2>
                <h1>{single.title}</h1>
            </div>
            {adGreeting && (
                <div css={styles.adGreeting}>
                    Hi \o/!! Thanks for clicking my ad! Below is the link to Spotify, have a listen and let me know what you think! DM me on social media and tell me if you liked the song or not, your feedback means the world to me! Have a great day! LD.
                </div>
            )}
            <div css={styles.links}>
                {single.spotifyLink != null && (links == null || links.includes('spotify')) && (
                    <SingleActionButton
                        singleTitle={single.title}
                        href={single.spotifyLink}
                        title="Play"
                        serviceName="spotify"
                        logo="/images/logo_spotify.svg" />
                )}
                {single.amazonLink != null && (links == null || links.includes('amazon')) && (
                    <SingleActionButton
                        singleTitle={single.title}
                        href={single.amazonLink}
                        title="Play"
                        serviceName="amazon"
                        logo="/images/logo_amazonmusic.svg" />
                )}
                {single.youtubeLink != null && (links == null || links.includes('youtube')) && (
                    <SingleActionButton
                        singleTitle={single.title}
                        href={single.youtubeLink}
                        title="Play"
                        serviceName="youtube"
                        logo="/images/logo_youtubemusic.svg" />
                )}
                {single.appleLink != null && (links == null || links.includes('apple')) && (
                    <SingleActionButton
                        singleTitle={single.title}
                        href={single.appleLink}
                        title="Play"
                        serviceName="apple"
                        logo="/images/logo_applemusic.svg" />
                )}
                {single.soundCloudLink != null && (links == null || links.includes('soundcloud')) && (
                    <SingleActionButton
                        singleTitle={single.title}
                        href={single.soundCloudLink}
                        title="Play"
                        serviceName="soundcloud"
                        logo="/images/logo_soundcloud.svg" />
                )}
                {/* {single.spotifyLink == null && single.youtubeLink == null && (
                    <SingleActionButton
                        singleTitle={single.title}
                        href={single.presaveLink}
                        title="Presave"
                        serviceName="distrokid"
                        logo="/images/logo_spotify.svg"
                        releaseDate={single.releaseDate}
                    />
                )} */}
            </div>

            {showDesc && (
                <div css={styles.desc}>
                    {single.desc.map(desc => (
                        <p key={desc}>{desc}</p>
                    ))}
                </div>
            )}
        </div>
    )
}

const styles = {
    desc: css`
        color: #555;
        background-color: #f8f8f8;
        padding: 0 10px;
        font-size: 1rem;
        line-height: 1.1rem;
        padding: 2em 0.5em 0 0.5em;

        font-size: 1.2rem;

        :before {
            color: #bbb;
            content: '\\201D';
            font-size: 5em;
            font-style: normal;
            line-height: 0;
            margin-right: 0.15em;
            vertical-align: -0.35em;
        }
        p:first-of-type {
            display: inline;
        }

        @media only screen and (max-width: 1000px) {
            font-size: 1rem;
        }
    `,
    main: css`
        width: 440px;
        margin: 0 auto 30px auto;
        font-size: 1.6em;
        font-weight: 400;
        line-height: 0;	
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;	
        border: 1px solid #eee;

        @media only screen and (max-width: 1000px) {
            width: 320px;
        }
        // width: 250px;
    `,
    cover: css`
    	width: 440px;
        height: 440px;
        @media only screen and (max-width: 1000px) {
            width: 320px;
            height: 320px;
        }
        // width: 250px;
        // height: 250px;
`,
    header: css`
        position: relative;
        padding: 12px 0;
        background-color: #000;
        h1 {
            text-align: center;
            font-size: 1.1rem;
            font-weight: 600;
            line-height: 1.2em;
            color: #FFF;
        }
        h2 {
            text-align: center;
            font-size: 1.2rem;
            font-weight: 600;
            line-height: 2em;
            color: #EEE;
        }
        :after {
            content: "";
            display: inline-block;
            left: 50%;
            margin-left: -12px;
            margin-bottom: -12px;
            width: 0;
            height: 0;
            bottom: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 12px solid #181818;
            position: absolute;
        }
    `,
    adGreeting: css`
        line-height: 1.2em;
        font-size: 0.8em;
        padding: 20px 20px 0 20px;    
        color: green;
        background-color: #fff;
    `,
    links: css`
    	background-color: #fff;
    `
}

export default Single;
